import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form"
export default class extends Controller {
  connect() {
  }

  resetForm() {
    this.element.reset()
  }

  submit() {
    this.element.requestSubmit()
  }
}
