import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="switcher"
export default class extends Controller {
  static targets = ["booleanTrigger", "form"]
  static values = { elementToShow: String, elementsToShow: Array, elementsToHide: Array, elementsToReset: Array, elementsToEnable: Array, elementsToDisable: Array }
  connect() {
    if (this.hasBooleanTriggerTarget) {
      this.show()
    }
  }

  switch() {
    this.elementsToShowValue.forEach(element => {
      let elementToShow = document.querySelector(`${element}`)
      elementToShow.classList.remove('hidden')
      elementToShow.setAttribute('value', null)
    })

    this.elementsToHideValue.forEach(element => {
      let elementToHide = document.querySelector(`${element}`)
      elementToHide.classList.add('hidden')
      elementToHide.setAttribute('value', null)
    })

    this.elementsToResetValue.forEach(element => {
      let elementToReset = document.querySelector(`${element}`)
      elementToReset.value = ""
      elementToReset.checked = false
    })

    this.elementsToEnableValue.forEach(element => {
      let elementToEnable = document.querySelector(`${element}`)
      elementToEnable.removeAttribute('disabled')
    })

    this.elementsToDisableValue.forEach(element => {
      let elementToDisable = document.querySelector(`${element}`)
      elementToDisable.setAttribute('disabled', 'disabled')
    })
  }

  show() {
    let elementToShow = document.querySelector(`${this.elementToShowValue}`)
    if (this.booleanTriggerTarget.checked) {
      elementToShow.classList.remove('hidden')
    } else {
      elementToShow.classList.add('hidden')
    }
  }
}
